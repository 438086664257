import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../../components/blocks/mdx-layout";
import { SEO } from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="About" description="A little bit about me and what I'm passionate about, what I do at work, and what else I do in my free time. Maybe you're also into photography or hiking?" noIndex mdxType="SEO" />

    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <div className="img-left-wrap-text">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/eaef588f65790c370356caa0ba9a062b/5a523/lennart-profile-pic.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAenLqBu2nWfLZJoCf//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMEEhETMv/aAAgBAQABBQJ3CJVk7vzLCvXR73jsWJJMS5wP/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASv/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BI//EAB0QAAICAQUAAAAAAAAAAAAAAAABAhEhEBIxUbH/2gAIAQEABj8Ccuja4VejtlyVHJlmWV6f/8QAHBAAAwACAwEAAAAAAAAAAAAAAAERMVEhQWFx/9oACAEBAAE/IZmsYIzXQ0yNMYQpar7JxFYvFZIr3PXhGo5tnIyfxT//2gAMAwEAAgADAAAAEPPwvf/EABcRAQEBAQAAAAAAAAAAAAAAAAERABD/2gAIAQMBAT8QIl0cTf/EABcRAQADAAAAAAAAAAAAAAAAAAERICH/2gAIAQIBAT8QcYo//8QAHRABAAMBAAIDAAAAAAAAAAAAAQARMSFBUWFxkf/aAAgBAQABPxBF6Ri3wS41t0CqlmflCXrqixhKjQvT0cCJKAPiLnotF59IKB8WNQSCjxsB6uf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Me in a black t-shirt in front of the beautiful alpes in austria.",
              "title": "Me in a black t-shirt in front of the beautiful alpes in austria.",
              "src": "/static/eaef588f65790c370356caa0ba9a062b/87945/lennart-profile-pic.jpg",
              "srcSet": ["/static/eaef588f65790c370356caa0ba9a062b/b95e4/lennart-profile-pic.jpg 256w", "/static/eaef588f65790c370356caa0ba9a062b/1779b/lennart-profile-pic.jpg 512w", "/static/eaef588f65790c370356caa0ba9a062b/87945/lennart-profile-pic.jpg 1024w", "/static/eaef588f65790c370356caa0ba9a062b/5a523/lennart-profile-pic.jpg 1200w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><strong parentName="p">{`Hi, I’m Lennart 👋`}</strong><br /><br />{`
I’m a designer turned software engineer from Darmstadt, Germany. I currently work at `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com"
        }}>{`Gatsby`}</a>{` on the open source software (OSS).
I’m passionate about working on open source projects & building thriving communities around them.`}<br />{`
My `}<a parentName="p" {...{
          "href": "https://github.com/LekoArts"
        }}>{`OSS code`}</a>{` has been used by thousands of people & I regularly share educational content in the form of `}<a parentName="p" {...{
          "href": "/writing"
        }}>{`posts`}</a>{`, `}<a parentName="p" {...{
          "href": "/appearances"
        }}>{`presentations`}</a>{`, and `}<a parentName="p" {...{
          "href": "/appearances"
        }}>{`videos`}</a>{`.`}</p>
    </div>
    <h2 {...{
      "id": "what-im-passionate-about"
    }}>{`What I’m passionate about`}</h2>
    <p>{`Similarly to how I learned everything about `}<a parentName="p" {...{
        "href": "/art"
      }}>{`design, 3D art, and photography`}</a>{` I’m a self-taught developer. Online developer communities, OSS projects, educators sharing their knowledge (for free & paid), mentors, colleagues, and friends — all those people helped me get to where I am today. And I’m very grateful for that. Early on I also helped out people, contributed to OSS projects and published my own projects on GitHub. That’s how I realized that I enjoy technical writing (e.g. public facing documentation), creating educational content and managing & interacting with communities. I deeply care about inclusivity & accessibility both in the communication with people and the content (e.g. website/documentation) itself.`}</p>
    <h2 {...{
      "id": "work"
    }}>{`Work`}</h2>
    <p>{`I work at `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com"
      }}>{`Gatsby`}</a>{` maintaining & further improving the OSS project. Together with my colleagues I implement bug fixes, performance improvements, new features, and review issues & PRs to help >300k users build websites. I’m also managing our various community platforms (GitHub Discussion, Discord) and integrate processes to better engage with our community.`}</p>
    <p>{`You can see my `}<a parentName="p" {...{
        "href": "/projects"
      }}>{`projects`}</a>{` overview for more technical details.`}</p>
    <h2 {...{
      "id": "hobbies"
    }}>{`Hobbies`}</h2>
    <p>{`If I’m not coding I like to spend my time with playing tennis & hiking in the mountains. I then often take my camera with me to take `}<a parentName="p" {...{
        "href": "/art/photography"
      }}>{`landscape photos`}</a>{` and also particularly enjoy editing the photos at the computer afterwards. I also enjoy creating other forms of `}<a parentName="p" {...{
        "href": "/art"
      }}>{`art`}</a>{` such as 3D art or UI design. Lastly, my kindle is always filled with fantasy & sci-fi books 📚.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      